import React from 'react';
import GalleryPreview from './GalleryPreview';
import AboutMe from './AboutMe';
import Blog from './Blog';
import Intro from './Intro';
import Contact from './Contact';

const Home = () => {
  return (
    <main className="w-full mx-auto"> 
      <Intro />        {/* Responsive Hero Section */}
      <AboutMe />      {/* Responsive About Me Section */}
      <GalleryPreview /> {/* Responsive Gallery Section */}
      <Blog />         {/* Responsive Blog Section */}

      {/* Add an id for contact section */}
      <section id="contact-section">
        <Contact />
      </section>
    </main>
  );
};

export default Home;
