import React from 'react';
import { motion } from 'framer-motion';
import { CameraIcon, CodeIcon, UserIcon, InstagramIcon, LinkedinIcon, FacebookIcon } from "lucide-react";

const AboutMe = () => {
  return (
    <section className="py-20 px-4 md:px-8 bg-gray-900 text-white">
      <div className="max-w-6xl mx-auto">
        {/* Heading */}
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        >
          About Me
          {/* Greetings, fellow adventurers of the visual realm! */}
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-12 items-center">
          {/* Avatar and Social Links */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="w-48 h-48 mx-auto mb-6 rounded-full overflow-hidden">
              <img src="https://i.ibb.co/9GhH9mg/20210522060151-IMG-2778-01.jpg" alt="Keshav Atal" className="w-full h-full object-cover" />
            </div>
            <div className="text-center">
              <h3 className="text-2xl font-semibold mb-2">Keshav Atal</h3>
              <p className="text-gray-300 mb-4">
                Photographer | CS Engineer | Club Leader
              </p>
             <div className="flex justify-center space-x-4">
  <a 
    href="https://www.instagram.com/keshav.atal" 
    target="_blank" 
    rel="noopener noreferrer"
    className="p-2 bg-transparent text-gray-500 hover:bg-gray-100 rounded-full transition-all"
  >
    <InstagramIcon className="w-5 h-5" />
  </a>
  
  <a 
    href="https://www.facebook.com/keshav.atal.39" 
    target="_blank" 
    rel="noopener noreferrer"
    className="p-2 bg-transparent text-gray-500 hover:bg-gray-100 rounded-full transition-all"
  >
    <FacebookIcon className="w-5 h-5" />
  </a>
  
  <a 
    href="https://www.linkedin.com/in/keshav-atal-a10b9a24b/" 
    target="_blank" 
    rel="noopener noreferrer"
    className="p-2 bg-transparent text-gray-500 hover:bg-gray-100 rounded-full transition-all"
  >
    <LinkedinIcon className="w-5 h-5" />
  </a>
</div>

            </div>
          </motion.div>

          {/* About Content */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <p className="text-gray-300 mb-6">
              I'm a Computer Science student and passionate photographer, blending technology with creativity, capture moments that tell compelling stories. As the Head of AU United Photography Club, I lead a vibrant community of photography enthusiasts.
            </p>
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div className="flex items-center gap-2">
                <CameraIcon className="w-5 h-5 text-purple-400" />
                <span>3+ years experience</span>
              </div>
              <div className="flex items-center gap-2">
                <CodeIcon className="w-5 h-5 text-purple-400" />
                <span>4th year CSE student</span>
              </div>
              <div className="flex items-center gap-2">
                <UserIcon className="w-5 h-5 text-purple-400" />
                <span>AU United Photography Club Head</span>
              </div>
              <div className="flex items-center gap-2">
                <UserIcon className="w-5 h-5 text-purple-400" />
                <span>Mr. Inaruwa-2019 Runner Up</span>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <span className="inline-block px-2 py-1 rounded text-xs font-semibold bg-gray-600 text-white">
                Portrait
              </span>
              <span className="inline-block px-2 py-1 rounded text-xs font-semibold bg-gray-600 text-white">
                Landscape
              </span>
              <span className="inline-block px-2 py-1 rounded text-xs font-semibold bg-gray-600 text-white">
                Street
              </span>
              <span className="inline-block px-2 py-1 rounded text-xs font-semibold bg-gray-600 text-white">
                Event
              </span>
              {/* <span className="inline-block px-2 py-1 rounded text-xs font-semibold bg-gray-600 text-white">
                Fine Art
              </span> */}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
