import React from 'react'
import { motion } from 'framer-motion'
import { ChevronDown } from 'lucide-react'
import { Link } from 'react-router-dom'

export default function HeroSection() {
  return (
    <div className="relative bg-gradient-to-b from-gray-900 to-gray-900 text-white min-h-screen flex flex-col justify-center items-center overflow-hidden px-4 sm:px-6 lg:px-8">
      {/* Greetings Heading */}
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl sm:text-3xl md:text-5xl lg:text-6xl font-semibold mb-8 sm:mb-12 mt-9 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
      >
        Greetings, fellow adventurers of the visual realm!
      </motion.h2>

      {/* Main Content Area */}
      <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-6xl px-4 md:px-6 lg:px-8">
        {/* Content Overlay */}
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-center lg:text-left mb-8 lg:mb-0 lg:pr-8 w-full lg:w-1/2"
        >
          <h1 className="text-3xl sm:text-3xl md:text-5xl lg:text-6xl font-bold leading-tight mb-4">
            Welcome to{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
              An_Imaginator_World
            </span>
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl font-light mb-8 max-w-2xl mx-auto lg:mx-0">
            Where imagination comes to life through the lens of creativity.
          </p>
          <div className="w-full flex flex-col md:flex-row justify-center lg:justify-start">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="flex flex-col sm:flex-row justify-center lg:justify-start gap-4 w-full sm:w-auto"
            >
              <button
                className="text-lg px-8 py-3 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all duration-300"
                onClick={() =>
                  document.getElementById('contact-section')?.scrollIntoView({
                    behavior: 'smooth'
                  })
                }
              >
                Connect
              </button>
              <Link
                to="/gallery"
                className="text-lg px-8 py-3 rounded-full border-2 border-white hover:bg-white hover:text-black transition-all duration-300 text-center"
              >
                Explore Gallery
              </Link>
            </motion.div>
          </div>
        </motion.div>

        {/* Photographer Image */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.6 }}
          className="w-full sm:w-3/4 md:w-2/3 lg:w-2/5 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg h-auto mt-8 lg:mt-0 p-4 sm:p-0"
        >
          <img
            src="https://i.ibb.co/LzRXQT5/hero.png"
            alt="Keshav Atal, a professional photographer"
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </motion.div>
      </div>

      {/* Scroll Indicator */}
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1, repeat: Infinity, repeatType: 'reverse' }}
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 hidden sm:block"
        aria-hidden="true"
      >
        <ChevronDown className="w-8 h-8 text-white opacity-75" />
      </motion.div>
    </div>
  )
}