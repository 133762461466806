import React from "react";
import { motion } from "framer-motion";
import {
  InstagramIcon,
  LinkedinIcon,
  FacebookIcon,
  MailIcon,
  // PhoneIcon,
  MapPinIcon,
} from "lucide-react";

const Contact = () => {
  return (
    <section className="py-20 px-4 md:px-8 bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        >
          Let's Connect
        </motion.h2>

        <div className="grid md:grid-cols-2 gap-12 items-start">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <div className="flex items-center space-x-4">
              <div className="w-16 h-16 rounded-full overflow-hidden">
                <img
                  alt="Keshav Atal"
                  className="w-full h-full object-cover"
                  src="https://i.ibb.co/9GhH9mg/20210522060151-IMG-2778-01.jpg"
                  width="64"
                  height="64"
                />
              </div>
              <div>
                <h3 className="text-2xl font-semibold">Keshav Atal</h3>
                <p className="text-gray-400">
                  Photographer | CSE Student | Club Leader
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <MailIcon className="w-5 h-5 text-purple-400" />
                <span>keshavatal012@gmail.com</span>
              </div>
              {/* <div className="flex items-center space-x-3">
                <PhoneIcon className="w-5 h-5 text-purple-400" />
                <span>+91 - 7363952257</span>
              </div> */}
              {/* <div className="flex items-center space-x-3">
                <MapPinIcon className="w-5 h-5 text-purple-400" />
                <span>Inaruwa, Nepal</span>
              </div> */}
              <div className="flex items-center space-x-3">
                <MapPinIcon className="w-5 h-5 text-purple-400" />
                <span>Visakhapatnam, India</span>
              </div>
            </div>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/keshav.atal"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-gray-700 text-purple-400 hover:bg-purple-400 hover:text-white rounded-full transition-all"
              >
                <InstagramIcon className="w-5 h-5" />
              </a>
              <a
                href="https://www.facebook.com/keshav.atal.39"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-gray-700 text-purple-400 hover:bg-purple-400 hover:text-white rounded-full transition-all"
              >
                <FacebookIcon className="w-5 h-5" />
              </a>
              <a
                href="https://www.linkedin.com/in/keshav-atal-a10b9a24b/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-2 bg-gray-700 text-purple-400 hover:bg-purple-400 hover:text-white rounded-full transition-all"
              >
                <LinkedinIcon className="w-5 h-5" />
              </a>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="bg-gray-800 p-8 rounded-lg shadow-lg"
          >
            <h3 className="text-2xl font-semibold mb-6">Send me a message</h3>
            <form className="space-y-4" name="connect" method="POST" data-netlify="true" onSubmit="submit">
            <input type="hidden" name="form-name" value="connect" />
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <input
                  className="bg-gray-700 border-gray-600 p-3 rounded-md text-white"
                  required
                  placeholder="First Name"
                  type="text"
                  name="First Name"
                />
                <input
                  className="bg-gray-700 border-gray-600 p-3 rounded-md text-white"
                  placeholder="Last Name"
                  required
                  type="text"
                  name="Last Name"
                />
              </div>
              <input
                className="bg-gray-700 border-gray-600 p-3 rounded-md text-white w-full"
                placeholder="Email"
                required
                type="email"
                name="Email"
              />
              <input
                className="bg-gray-700 border-gray-600 p-3 rounded-md text-white w-full"
                placeholder="Subject"
                required
                type="text"
                name="Subject"
              />
              <textarea
                className="bg-gray-700 border-gray-600 p-3 rounded-md text-white w-full"
                placeholder="Your message"
                required
                rows="4"
                name="Message"
              />
              <div data-netlify-recaptcha="true" ></div>
              <button
                type="submit"
                className="w-full bg-purple-600 hover:bg-purple-700 text-white p-3 rounded-md"
                name="Submit"
              >
                Send Message
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;