import React from 'react';
import { Facebook, Instagram, Linkedin, Mail } from 'lucide-react';

const socialLinks = [
  { name: 'Facebook', icon: Facebook, url: 'https://www.facebook.com/keshav.atal.39' },
  { name: 'Instagram', icon: Instagram, url: 'https://www.instagram.com/an_imaginator_world/' },
  { name: 'LinkedIn', icon: Linkedin, url: 'https://www.linkedin.com/in/keshav-atal-a10b9a24b/' },
  { name: 'Email', icon: Mail, url: 'mailto:Keshavatal012@gmail.com' }
];

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-white py-8">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold mb-6 text-center">AN IMAGINATOR WORLD</h2>
        <nav className="flex justify-center space-x-6 mb-6">
          {socialLinks.map((link) => (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300 transition-colors duration-300"
              aria-label={link.name}
            >
              <link.icon className="h-6 w-6" />
            </a>
          ))}
        </nav>
        <div className="text-center text-sm">
          <p>&copy; {currentYear} Keshav Atal. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;