import React, { useState, useEffect } from 'react'
import { X } from 'lucide-react'
import { motion } from 'framer-motion'

const categories = ['All', 'Portraits', 'B&W', 'Landscape', 'Street']

export default function Gallery() {
  const [activeCategory, setActiveCategory] = useState('All')
  const [modalImage, setModalImage] = useState(null)
  const [images, setImages] = useState([
    { id: 1, src: 'https://i.ibb.co/Df2qqZc/IMG-5124-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/x59gbD8/20210810054658-IMG-7467.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/Hx27JHD/20210825061049-IMG-8924-02.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/487yVsr/IMG-6714-01-3.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/jJF5HRV/20211204-171645.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/F8PVJFL/20210413054440-IMG-9697-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/JzGYx7n/20210509044025-IMG-1107-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/syVPmqx/20211127231615-IMG-2434-01-1.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/x8d9qx4/20211206044303-IMG-2764-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/hCtbL5d/IMG-0256.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/BTHjJ0c/IMG-0278.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/bzkKP5Q/IMG-0272.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/5hhktT3/IMG-0306.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/whWgmC3/IMG-0354.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/84m9F0L/IMG-0927.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/4f9sSPT/IMG-2221-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/wdzkXCy/IMG-2264.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/tqvjh0y/IMG-2327-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/8mkPzvP/IMG-3153.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/6rHkcvf/IMG-3441-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/mzd3gKM/IMG-6359-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/37xYzN2/IMG-6632.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/H7HzCqR/IMG-6939.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/686zbxj/IMG-7015-1-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/5cmn4Xy/IMG-9131.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/Dtsw44V/IMG-9127.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/0tRPtsQ/IMG-9141.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/3yY0KqP/IMG-9133.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/JHCtbN6/IMG-9320.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/yn1msjv/IMG-9324.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/WgfPmXc/IMG-9928-1-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/sgKsh3Z/IMG-20220102-153314-118.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/LPRBbH0/20230616231850-IMG-6406.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/fSsMZMz/20230622051008-IMG-6566-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/XVGfSyn/20230630053340-IMG-7455-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/wh7dznc/IMG-5002-1-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/7rzj63R/IMG-5427-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/54V6M1P/IMG-5602.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/xJRHCKP/IMG-5551.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/vL76wCK/IMG-5644.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/vdjD2Mb/20201220231237-IMG-0947-02-02-3.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/xg6PC7F/20210123230805-IMG-4112-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/mGvGb12/20210407220455-IMG-9293.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/3zyjbG5/20210408055441-IMG-9315-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/qDVJVgF/20210514072105-IMG-2051-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/7YJKm8D/20210514124607-IMG-2135-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/sWCLRbN/20210623053954-IMG-5339-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/RTqJzzD/20210810085555-IMG-7540-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/4tVrcxh/20211011223122-IMG-0138.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/LY9Zwst/20211018045233-IMG-0214-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/2FqDxWz/20210408055459-IMG-9316-1-01-1-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/YWhTcCp/IMG-2584.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/G3Rk60G/20210623054326-IMG-5358-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/S0bsk9D/20211104081619-IMG-0787-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/12tQj6t/20211127051314-IMG-2190-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/Xj4dMHj/keshav-sphotography099.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/YZxvxLs/20230626202619-IMG-7095-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/Vp60rYx/20230622051433-IMG-6597.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/VJCR9ZC/20230622053904-IMG-6717-02.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/KqMWj1H/IMG-20220126-160259-652.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/phSWhgH/IMG-8737.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/51s62CD/IMG-5689.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/dPgYVcb/IMG-4574.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/586Xcm3/IMG-2140-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/mcFZ7Rj/IMG-1640-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/J21vd1j/IMG-0273.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/Y0C9CX6/20210828060157-IMG-9270-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/LR8Z9t7/20210509043931-IMG-1102.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/NYvGkF2/diwasdeepak.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/G2cFJcS/IMG-1465.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/TgNZmrM/IMG-9120.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/hsv1tkv/20230704000911-IMG-7947-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/26qvjzJ/20230713052047-IMG-8057.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/ys0H2pR/20210512082345-IMG-1445-01.webp', category: 'Portraits', alt: 'Portrait' },
{ id: 1, src: 'https://i.ibb.co/MS6VZmK/IMG-2828.webp', category: 'Portraits', alt: 'Portrait' },
    { id: 1, src: 'https://i.ibb.co/HKyLZ1t/20230701033339-IMG-7721.webp', category: 'Portraits', alt: 'Portrait' },



    { id: 2, src: 'https://i.ibb.co/2n5Z5Z5/20210729215616-IMG-6561-1.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/VY4Hq74/20210324071329-IMG-7692-01.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/dL30TjR/20210323064457-IMG-7629-02.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/10xRVNh/20210323064254-IMG-7620-01-01.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/MNCfvZb/20210323063137-IMG-7602-01.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/N1jvgYd/IMG-3749.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/3YK9Bf6/IMG-4606-01-1.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/BzrdwXM/IMG-7333.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/W5qPYk8/IMG-7344.webp', category: 'B&W', alt: 'Black and white' },
{ id: 2, src: 'https://i.ibb.co/HtyLtk7/20210730004227-IMG-6568.webp', category: 'B&W', alt: 'Black and white' },
    { id: 2, src: 'https://i.ibb.co/tQ66rzV/Photo-Collage-20210821-120407012.webp', category: 'B&W', alt: 'Black and white' },




{ id: 3, src: 'https://i.ibb.co/zsbPq3N/20210326054915-IMG-7863-EFFECTS-01-1.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/K51H38Z/IMG-0758.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/pdVYNXB/20230701023704-IMG-7568.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/D4vXsXQ/20230701021236-IMG-7516.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/N1jvgYd/IMG-3749.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/2kVY549/IMG-4753-01.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/wCQf2tT/IMG-5487.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/fvT9sbD/IMG-7849.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/HTrryrW/IMG-7853.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/1dNm8Wd/IMG-7856.webp', category: 'Landscape', alt: 'Landscape' },
{ id: 3, src: 'https://i.ibb.co/rbb6V0K/IMG-7859.webp', category: 'Landscape', alt: 'Landscape' },
    { id: 3, src: 'https://i.ibb.co/qmkbvzM/IMG-8524.webp', category: 'Landscape', alt: 'Landscape' },



    { id: 4, src: 'https://i.ibb.co/zGwM11p/IMG-8314.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/xgRKpJC/IMG-6169.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/7NymvKv/IMG-7450-2.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/sbTS6BX/20230701024537-IMG-7585-2.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/7W7xw5k/20230701033324-IMG-7720.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/16Psb59/20230701033445-IMG-7727-01-1.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/gwsJnL9/20210704082148-IMG-5656-01-1.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/3Mb45rp/IMG-8297.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/4VBZ7vC/20210618051012-IMG-5082-01.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/F0Q5w3j/20230701032022-IMG-7679.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/tcmBk4w/20230701035131-IMG-7829.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/Nn9v5tw/20210407045735-IMG-9124-4.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/vcmG5q2/20210331204839-IMG-8484-03.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/Fwn1DMV/20210331204259-IMG-8442-01-1.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/mb4hyVP/IMG-8287.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/QjYY2t2/20210404034741-IMG-8805-1.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/5WK1nTg/Photo-Grid-Site-1681928482147.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/t88ZDby/IMG-9123-2.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/q99nQ8v/20230701035214-IMG-7840.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/0c906qB/IMG-8880-2.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/KrQyX2g/IMG-8609.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/BKpKqKX/IMG-8636.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/rMbvr4b/20230701034658-IMG-7796-1.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/qmkbvzM/IMG-8524.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/MR4DztL/IMG-7724.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/bRDvmCL/IMG-7714.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/pz3Kp4G/IMG-8286.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/kxtZHpd/20230701034338-IMG-7775.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/bLLWvJ4/20230701024419-IMG-7580.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/Gvr4Nzm/20230701015519-IMG-7495.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/4tKzK4h/IMG-0183.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/qMhsHzy/IMG-4798.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/vjZ1hnB/IMG-8310.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/Vp60rYx/20230622051433-IMG-6597.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/xG4pWm8/IMG-20210618-224504-662.webp', category: 'Street', alt: 'Street' },
{ id: 4, src: 'https://i.ibb.co/hmqzXG6/IMG-4376.webp', category: 'Street', alt: 'Street' },
  ])

  useEffect(() => {
    setImages(prevImages => [...prevImages].sort(() => Math.random() - 0.5))
  }, [activeCategory])

  const filteredImages = activeCategory === 'All' 
    ? images 
    : images.filter(img => img.category === activeCategory)

  const openModal = (image) => {
    setModalImage(image)
  }

  const closeModal = () => {
    setModalImage(null)
  }

  // const navigateImage = (direction) => {
  //   const currentIndex = filteredImages.findIndex(img => img.id === modalImage.id)
  //   const newIndex = (currentIndex + direction + filteredImages.length) % filteredImages.length
  //   setModalImage(filteredImages[newIndex])
  // }

  const MasonryGallery = () => (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-4"
    >
      {filteredImages.map((image) => (
        <motion.div 
          key={image.id} 
          className="mb-4 break-inside-avoid group relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img 
            src={image.src} 
            alt={image.alt} 
            className="w-full rounded-lg shadow-md group-hover:shadow-xl transition-all duration-300 cursor-pointer" 
            onClick={() => openModal(image)}
            loading="lazy"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = "https://i.ibb.co/mTGwfVN/Imaginator-logo.png"
            }}
          />
        </motion.div>
      ))}
    </motion.div>
  )

  const Modal = () => {
    if (!modalImage) return null

    return (
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" 
        onClick={closeModal}
      >
        <div className="relative max-w-4xl max-h-[90vh] h-full w-full mx-4" onClick={(e) => e.stopPropagation()}>
          <img 
            src={modalImage.src} 
            alt={modalImage.alt} 
            className="w-full h-full object-contain rounded-lg"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = "imaginator/public/Imaginatorlogo.PNG"
            }}
          />
          <button 
            className="absolute top-4 right-4 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-all duration-300"
            onClick={closeModal}
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
          
        </div>
      </motion.div>
    )
  }

  return (
    <main className="bg-gradient-to-b from-gray-900 to-gray-900 text-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-4xl sm:text-5xl md:text-6xl font-bold mb-8 text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600"
      >
        Photo Gallery
      </motion.h1>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
        className="flex justify-center mb-8 flex-wrap"
      >
        {categories.map(category => (
          <button
            key={category}
            className={`m-2 px-6 py-3 rounded-full transition-all duration-300 text-base sm:text-lg ${
              activeCategory === category 
                ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-white' 
                : 'bg-gray-800 text-white hover:bg-gray-700'
            }`}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
        ))}
      </motion.div>
      <MasonryGallery />
      <Modal />
    </main>
  )
}