import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';

export default function Blog() {
  const [expanded, setExpanded] = useState(false);

  const paragraphs = [
    "My foray into photography began in an unlikely way. In December 2019, I was honored with the title of Mr. Inaruwa Runner-Up, and as a model, I sought to expand my portfolio. This led me to collaborate with various photographers, where I gained insights into the art of photography. It was during this time that I learned about the fundamental concepts of ISO, Shutter Speed, and Aperture, and how they work in harmony to produce a great photograph.",
    "With a burgeoning interest in photography, I was eager to delve deeper into this craft. I learned about the technical workings of a camera, the essential rules of photography, and the various types of photography under the guidance of professional photographers. My brother, Sushant Raman Pokhrel, mentored me on photo editing, and this further fueled my passion for photography.",
    "As I grew more enamored with photography, I appealed to my family for a camera. While I found a suitable second-hand camera at an affordable price, my family urged me to aim higher and instead gifted me with a new entry-level professional DSLR Canon 80d Camera. With this prized possession, I felt a sense of elation and was eager to showcase my photography skills.",
    "Over time, I have honed my photography skills and captured numerous photographs."
  ];

  return (
    <section className="py-20 px-4 md:px-8 bg-gradient-to-b from-gray-800 to-gray-900 text-white">
      <div className="max-w-4xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-4xl font-bold mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        >
          My Photography Journey
        </motion.h2>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="bg-gray-800 rounded-lg shadow-lg p-8 relative overflow-hidden"
        >
          <h3 className="text-2xl font-semibold mb-6 text-center text-gray-300">If you know me, you know my story.</h3>
          <div className="space-y-6 text-gray-300 leading-relaxed">
            <p className="text-justify">{paragraphs[0]}</p>
            <AnimatePresence>
              {expanded && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  {paragraphs.slice(1).map((paragraph, index) => (
                    <p key={index} className="mt-4 text-justify">{paragraph}</p>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="flex justify-center mt-8">
            <button
              className="bg-purple-600 hover:bg-purple-700 text-white px-6 py-3 rounded-lg flex items-center justify-center transition-colors duration-300"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <>
                  Read Less
                  <ChevronUp className="ml-2" size={20} />
                </>
              ) : (
                <>
                  Read More
                  <ChevronDown className="ml-2" size={20} />
                </>
              )}
            </button>
          </div>
        </motion.div>
      </div>
    </section>
  );
}
