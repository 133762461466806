import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export default function GalleryPreview() {
  const previewImages = [
    'https://i.ibb.co/HtyLtk7/20210730004227-IMG-6568.webp',
    'https://i.ibb.co/xgRKpJC/IMG-6169.webp',
    'https://i.ibb.co/3Mb45rp/IMG-8297.webp',
    'https://i.ibb.co/4tKzK4h/IMG-0183.webp',
    'https://i.ibb.co/qMhsHzy/IMG-4798.webp',
    'https://i.ibb.co/10xRVNh/20210323064254-IMG-7620-01-01.webp',
    'https://i.ibb.co/tQ66rzV/Photo-Collage-20210821-120407012.webp',
    'https://i.ibb.co/xJRHCKP/IMG-5551.webp',
    'https://i.ibb.co/sbTS6BX/20230701024537-IMG-7585-2.webp',
    'https://i.ibb.co/qmkbvzM/IMG-8524.webp',
    'https://i.ibb.co/QjYY2t2/20210404034741-IMG-8805-1.webp',
    'https://i.ibb.co/Df2qqZc/IMG-5124-01.webp',
    'https://i.ibb.co/x59gbD8/20210810054658-IMG-7467.webp',
    'https://i.ibb.co/2kVY549/IMG-4753-01.webp',
    'https://i.ibb.co/HTrryrW/IMG-7853.webp',
    'https://i.ibb.co/Hx27JHD/20210825061049-IMG-8924-02.webp',
    'https://i.ibb.co/487yVsr/IMG-6714-01-3.webp',
    'https://i.ibb.co/wCQf2tT/IMG-5487.webp',
    'https://i.ibb.co/jJF5HRV/20211204-171645.webp',
    'https://i.ibb.co/N1jvgYd/IMG-3749.webp',
    'https://i.ibb.co/fvT9sbD/IMG-7849.webp',
  ];

  return (
    <section className="py-20 px-4 md:px-8 bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <div className="max-w-6xl mx-auto">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="text-3xl sm:text-4xl font-bold mb-8 sm:mb-12 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-600"
        >
          Explore An_Imaginator_World Gallery
        </motion.h2>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="columns-1 sm:columns-2 md:columns-3 lg:columns-4 gap-4"
        >
          {previewImages.map((src, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="mb-4 break-inside-avoid group relative overflow-hidden rounded-lg"
            >
              <img
                src={src}
                alt={`Preview ${index + 1}`}
                className="w-full rounded-lg shadow-md transition-all duration-300 cursor-pointer"
                loading="lazy"
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = "/Imaginatorlogo.PNG";
                }}
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                {/* <span className="text-white text-lg font-semibold">View</span> */}
              </div>
            </motion.div>
          ))}
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="flex justify-center mt-12"
        >
          <Link
                to="/gallery"
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-6 rounded-full"
              >
                Explore Full Gallery
              </Link>
        </motion.div>
      </div>
    </section>
  );
}
