import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import Contact from './components/Contact';
import Footer from './components/Footer';

const preloadImages = [
  'https://i.ibb.co/pKx2zhS/20210413054354-IMG-9692-01.webp',
  'https://i.ibb.co/c6XQt3s/IMG-5500-2-01.webp',
  'https://i.ibb.co/bHvPZdV/20210220054614-IMG-6435-01-01.webp',
  'https://i.ibb.co/F46qCPc/IMG-4753-01.webp',
  'https://i.ibb.co/nbqjrBr/IMG20210422183757-01-Copy.webp',
  'https://i.ibb.co/d6wxnR6/MANATWORK-04-Copy.webp',
  'https://i.ibb.co/Ntwdykt/IMG-3153.webp',
  'https://i.ibb.co/wJ8Trt3/20210312052225-IMG-6809-01-1.webp',
];

const Preloader = ({ onLoadingComplete }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % preloadImages.length;
        setProgress((newIndex + 1) * (100 / preloadImages.length));
        return newIndex;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        onLoadingComplete();
      }, 700); // Delay to show 100% briefly
    }
  }, [progress, onLoadingComplete]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
      <div className="text-center">
        <div className="mb-8">
          <img
            src={preloadImages[currentImageIndex]}
            alt={`Preload ${currentImageIndex + 1}`}
            className="w-full max-w-2xl h-auto object-cover rounded-lg shadow-2xl"
          />
        </div>
        <div className="w-full max-w-2xl mx-auto bg-gray-200 rounded-full h-2.5">
          <div
            className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="mt-4 text-white text-xl font-semibold">Loading your experience... {Math.round(progress)}%</p>
      </div>
    </div>
  );
};

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadingComplete = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Preloader onLoadingComplete={handleLoadingComplete} />
      ) : (
        <Router>
          <div className="min-h-screen bg-gray-100 text-gray-900">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/blog" element={<Blog />} />
            </Routes>
            <Footer />
          </div>
        </Router>
      )}
    </>
  );
}

export default App;